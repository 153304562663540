
















































































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  InlenerCao,
  InlenerCaoListAction,
  InviteInlenerAction,
  InlenerInviteListAction,
  CaoComplianceListAction,
  CaoCompliance,
} from '@/store/account/types';
import {
  DeleteInlenerCaoKoppelingAction,
  ToggleInlenerCaoAction,
  ToggleCaoComplianceAction,
  CheckInlenerCaoAction,
} from '@/store/main/types';
import VButton from '@/components/VButton.vue';
import FormField from '@/utilities/validation/formfield';
import ValidatedForm from '@/utilities/validation/validatedform';
import createList from '@/utilities/lists';
import emailValidator from '@/utilities/validators/email';
import { AxiosResponse } from 'axios';
import debounce from '@/utilities/debounce';
import UserInfo from '@/interfaces/userInfo';
import { toReadableDateFormat } from '@/utilities/filters';

@Component({
  components: { VButton },
  filters: { toReadableDateFormat },
})
export default class AccountInleners extends Vue {

  get inviteInlenerFormValid() { return this.inviteInlenerForm.fieldValidity(); }

  @Action('inlenerCaos', { namespace: 'account' }) private getInlenerCaos!: InlenerCaoListAction;
  @Action('caoCompliances', { namespace: 'account' }) private getCaoCompliances!: CaoComplianceListAction;
  @Action('inlenerInvites', { namespace: 'account' }) private getInlenerInvites!: InlenerInviteListAction;
  @Action('inviteInlenerUser', { namespace: 'account' }) private inviteInlenerUser!: InviteInlenerAction;
  @Action('toggleInlenerCaos', { namespace: 'main' }) private toggleInlenerCaos!: ToggleInlenerCaoAction;
  @Action('toggleCaoCompliances', { namespace: 'main' }) private toggleCaoCompliances!: ToggleCaoComplianceAction;
  @Action('checkInlenerCao', { namespace: 'main' }) private checkInlenerCao!: CheckInlenerCaoAction;
  @Action('deleteInlenerCaoKoppeling', { namespace: 'main' })
  private deleteInlenerCaoKoppelingAction!: DeleteInlenerCaoKoppelingAction;

  @Getter('userInfo', { namespace: 'account' }) private userInfo!: UserInfo;

  // Simply set to hard coded till we have roles implemented
  private isAdmin = true;
  private superAdmin = false;

  private inlenerCaos = createList<InlenerCao>({
    contains: '',
    page: 1,
    only_user: false,
  });
  private caoCompliances = createList<CaoCompliance>({
    contains: '',
    page: 1,
    only_user: false,
  });
  private inlenerInvites = createList<{sent_at: string; sent_to_email: string }>({ page: 1 });

  private modalInlenerCao: InlenerCao | null = null;
  private modalComplianceCao: CaoCompliance | null = null;
  private deletingId: number | null = null;
  private requestingCheck = false;
  private showServerError = false;
  private serverError = '';

  private inviteInlenerForm: ValidatedForm = new ValidatedForm({
    email: new FormField(emailValidator, ''),
  });

  private debouncedGetInlenerCaos = debounce(this.fetchInlenersCaos);
  private debouncedGetCaoCompliances = debounce(this.fetchCaoCompliances);

  private created() {
    this.fetchInlenersCaos();
    this.fetchCaoCompliances();
    this.fetchInlenerInvites();
  }

  private fetchInlenersCaos() {
    this.inlenerCaos.fetching = true;
    this.getInlenerCaos(this.inlenerCaos.filters).then((result) => {
      this.inlenerCaos = {
        ...this.inlenerCaos,
        ...result,
      };
    }).catch(() => {
      this.inlenerCaos.models = [];
    }).finally(() => this.inlenerCaos.fetching = false);
  }

  private fetchCaoCompliances() {
    this.caoCompliances.fetching = true;
    this.getCaoCompliances(this.caoCompliances.filters).then((result) => {
      this.caoCompliances = {
        ...this.caoCompliances,
        ...result,
      };
    }).catch(() => {
      this.caoCompliances.models = [];
    }).finally(() => this.caoCompliances.fetching = false);
  }

  private fetchInlenerInvites() {
    this.inlenerInvites.fetching = true;
    this.getInlenerInvites(this.inlenerInvites.filters).then((response) => {
      this.inlenerInvites = {
        ...this.inlenerInvites,
        ...response,
      };
    }).finally(() => {
      this.inlenerInvites.fetching = false;
    });
  }

  @Watch('inlenerCaos.filters.contains')
  @Watch('inlenerCaos.filters.page')
  @Watch('inlenerCaos.filters.only_user')
  private inlenersCaosFilterWatcher() {
    this.debouncedGetInlenerCaos();
  }

  @Watch('caoCompliances.filters.contains')
  @Watch('caoCompliances.filters.page')
  @Watch('caoCompliances.filters.only_user')
  private caoCompliancesFilterWatcher() {
    this.debouncedGetCaoCompliances();
  }

  @Watch('inlenerInvites.filters.page')
  private inlenersInviteFilterWatcher() {
    this.fetchInlenerInvites();
  }

  private showCaoCheckModal(inlenerCao: InlenerCao) {
    this.modalInlenerCao = inlenerCao;
    (this.$refs['check-cao-modal-1'] as any).show();
  }

  private requestCheck() {
    this.requestingCheck = true;
    this.checkInlenerCao({ inlener_cao_id: this.modalInlenerCao!.id.toString() })
      .finally(() => {
        this.inlenerCaos.filters.page = 1;
        this.inlenerCaos.fetching = true;
        this.getInlenerCaos(this.inlenerCaos.filters).then((result) => {
          this.inlenerCaos = {
            ...this.inlenerCaos,
            ...result,
          };
        }).finally(() => {
          this.inlenerCaos.fetching = false;
          (this.$refs['check-cao-modal-1'] as any).hide();
          this.requestingCheck = false;
        });
      });
  }
  private submitInviteInlenerUser() {
    this.showServerError = false;
    if (this.inviteInlenerForm.formValid()) {
      this.inviteInlenerUser({
        email: this.inviteInlenerForm.fields.email.value,
      }).then((response) => {
        (this.$refs['inlener-invited-modal'] as any).show();
        this.inlenerInvites.models = response.models;
      }).catch((response: AxiosResponse) => {
        this.serverError = response.data[0];
        this.showServerError = true;
      });
    }
  }

  private toggleInlenerCao(inlenerCao: InlenerCao) {
    this.toggleInlenerCaos({
      inlener_cao_id: inlenerCao.id,
      api: inlenerCao.api,
      tool: inlenerCao.tool,
    });
  }

  private toggleCaoCompliance(caoCompliance: CaoCompliance) {
    this.toggleCaoCompliances({
      cao_compliance_id: caoCompliance.id,
      api: caoCompliance.api,
    });
  }

  private showCaoNotInAgreementModal(inlenerCao: InlenerCao) {
    this.modalInlenerCao = inlenerCao;
    (this.$refs['cao-not-in-agreement-modal-1'] as any).show();
  }

  private showComplianceCaoNotInAgreementModal(complianceCao: CaoCompliance) {
    this.modalComplianceCao = complianceCao;
    (this.$refs['compliance-cao-not-in-agreement-modal-1'] as any).show();
  }

  private deleteInlenerCaoKoppeling(inlenerCaoId: number, index: number) {
    this.deletingId = inlenerCaoId;
    this.deleteInlenerCaoKoppelingAction({ inlener_cao_id: inlenerCaoId })
    .then(() => {
      this.fetchInlenersCaos();
    })
    .finally(() => {
      this.deletingId = null;
    });
  }
}
